<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDescarga" :z-index="999">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Contratos - Datos adicionales'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                         <template
                            v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div class="btnAccionesTitulo pb-2">
                                        <span>Layout</span>
                                    </div>

                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div @click="abrirModalLayoutEmpleados('descargar')" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="abrirModalLayoutEmpleados('subir')" class="btnAccionesItem py-2">
                                            <span>Importar layout</span><br />
                                        </div>
                                    </template>
                                    
                                </div>
                            </v-menu>
                        </template>
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Registro patronal"
                                    :items="itemsRegistrosPatronales"
                                    item-text="nombreClave"
                                    item-value="id"
                                    persistent-hint
                                    v-model="registro_patronal_id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresa"
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empresa_value"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Nombre del empleado"
                                    v-model="nombre_empleado"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre_empleado}}</div></td>
                                <td v-if="rol == 'root'"><div>{{item.cliente}}</div></td>
                                <td><div>{{item.registro_patronal}}</div></td>
                                <td><div>{{item.empresa_nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="permisos.delete">
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="900px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" class="d-flex justify-center my-10" v-if="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-if="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <template v-if="rol == 'root'">
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </template>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Registro patronal" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Registro patronal"
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombreClave"
                                                item-value="id"
                                                persistent-hint
                                                v-model="registro_patronal_id"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empleado"
                                                :items="itemsEmpleados"
                                                item-text="nombre_completo"
                                                item-value="id"
                                                persistent-hint
                                                v-model="datos.empleado_id"
                                                required 
                                                :error-messages="errors" 
                                                :loading="loadingEmpleados"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Campo" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Campo"
                                                :items="itemsCampos"
                                                item-text="campo_nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="datos.contrato_campo_id"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>


                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <div class="row">
                                            <div class="col-lg-12" id="app" style="height:450px; padding-top:0px;">
                                                <div class="document-editor__toolbar"></div>
                                                <div class="document-editor__editable-container">
                                                    <ckeditor 
                                                        id="ckEditor" 
                                                        :editor="editor" 
                                                        v-model="datos.valor" 
                                                        :config="editorConfig"
                                                        @ready="onReady"
                                                    >
                                                    </ckeditor>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                                v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                        <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                            <v-alert
                                dense
                                outlined
                                type="error"
                                
                            >
                                El usuario no tiene permiso para editar el registro.
                            </v-alert>
                        </div>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog v-model="dialogLayoutEmpleados" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayoutEmpleados"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="formArchivo" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloLayoutEmpleados }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Registro patronal" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Registro patronal"
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombreClave"
                                                item-value="id"
                                                persistent-hint
                                                v-model="registro_patronal_id"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="accionLayout === 'subir'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivo.archivo"
                                                accept=".xlsx"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                            <v-btn class="btnGuardar" v-if="accionLayout === 'subir'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            <v-btn class="btnGuardar" v-if="accionLayout === 'descargar'" @click="layout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import apiContratos from '@/api/contratos';
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiEmpleados from '@/api/empleados';
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "contratos-adicionales/datos/find",
            columns     : [
                {
                    label: 'Empleado',
                    name: 'empleado_id',
                    filterable: false
                },
                {
                    label: 'Cliente',
                    name: 'cliente',
                    filterable: false
                },
                {
                    label: 'Registro patronal',
                    name: 'registro_patronal',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'empresa_nombre',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            datos           : {
                id                  : null,
                cliente_id          : null,
                empresa_id          : null,
                empleado_id         : null,
                registro_patronal_id: null,
                contrato_campo_id   : null,
                valor               : '',
            },
            datosLogin      : null,
            rol             : null,
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : "",
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            itemsRegistrosPatronales:[],
            registro_patronal_id: null,
            itemsCampos     : [],
            itemsEmpleados  : [],
            loadingEmpleados: false,

            editor: DecoupledEditor,
            editorConfig: {
                language: "es",
                allowedContent : true,

                toolbar: {
                    items: [
                        'heading', '|',
                        'alignment', '|',
                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                        'link', '|',
                        'bulletedList', 'numberedList', 'todoList',
                        '-', // break point
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                        'code', 'codeBlock', '|',
                        'insertTable', '|',
                        'outdent', 'indent', '|',
                        'uploadImage', 'blockQuote', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
            sheetHeight : 1342, 
            maxSheetHeight:1342,
            currentZoom : 90,

            overlayDescarga         : false,
            frase                   : '',
            accionLayout            : '',
            dialogLayout            : false,
            dialogLayoutEmpleados   : false,
            tituloLayoutEmpleados   : '',
            archivo                 : {
                cliente_id          : null,
                empresa_id          : null,
                registro_patronal_id: null,
                archivo             : null,
            },
            nombre_empleado         : null,
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
            
        }
    },
     watch: {
        cliente_value: function(val, oldVal){
            if(val!= null){
                this.getRegistrosPatronales(val);
                this.getCampos();
            }
        },
        registro_patronal_id:function(val, oldVal){
            if(val!= null){
                this.getEmpresas()
            }
        },
        empresa_value:function(val, oldVal){
            if(val!=null){
                this.getEmpleados();
            }
        }
    },
    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        onReady(editor) {
           //incicia el CK Editor
            //const toolbarContainer = document.querySelector(  '.document-editor__toolbar' );
            //toolbarContainer.appendChild( editor.ui.view.toolbar.element );

            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            if(this.rol == 'root'){
                this.getClientes();
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(accion == "add") {
                this.tituloModal    = "Nuevo datos";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal                = "Actualizar datos";
                this.imagen                     = "/static/modal/catalogoSATupdate.svg";
                this.datos.id                   = data.id;
                this.datos.cliente_id           = data.cliente_id;
                this.datos.empresa_id           = data.empresa_id;
                this.datos.empleado_id          = data.empleado_id;
                this.datos.contrato_campo_id    = data.contrato_campo_id;
                this.datos.valor                = data.valor;

                this.cliente_value              = data.cliente_id;
                this.registro_patronal_id       = data.registro_patronal_id;
                this.empresa_value              = data.empresa_id;
            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogLayout = false;
            this.dialogLayoutEmpleados = false;

            this.resetValues();
        },
        resetValues() {

            this.datos.id                   = null;
            this.datos.cliente_id           = null;
            this.datos.empresa_id           = null;
            this.datos.empleado_id          = null;
            this.datos.contrato_campo_id    = null;
            this.datos.valor                = '';
            this.datos.registro_patronal_id = null;
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.registro_patronal_id       = null;
            this.overlayDescarga            = false;
            this.archivo                = {
                cliente_id              : null,
                empresa_id              : null,
                registro_patronal_id    : null,
                archivo                 : null,
            };

            this.clientes                   = [];
            this.itemsRegistrosPatronales   = [];
            this.empresas                   = [];
            this.nombre_empleado            = null;

            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formArchivo) {
                    this.$refs.formArchivo.reset();
                }
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }


            this.datos.cliente_id = this.cliente_value;
            this.datos.empresa_id = this.empresa_value;
            this.datos.registro_patronal_id =  this.registro_patronal_id;

            
            if(this.accion === "add") {
                apiContratos.storeDatos(this.datos).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "Los datos se guardaron satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiContratos.updateDatos(this.datos).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "Los datos se actualizaron satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este registro?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiContratos.deleteDatos(data.id).then(response => {
                        this.$refs.tabla.getData();
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },



        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes cuando el rol es root y el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        async getRegistrosPatronales(cliente){
            this.itemsRegistrosPatronales = [];

            if(cliente == null){
                return;
            }
            
            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: cliente 
            };
            await apiRegistroPatronal.find(param).then(response => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
        },

        /**
         * @method getEmpresas Este método realiza petición a la API de empresas.
         * @description Realiza petición a la API de empresas cuando el cliente es asigando y el resultado lo almacena en el array itemEmpresas[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getEmpresas() {

            if(this.registro_patronal_id == null){
                this.empresas = []
                return
            }

            let RP = [];
            RP.push(this.registro_patronal_id);
            this.empresas = [];
            
            let param = {   
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value,
                arrayRegistroPatronal: JSON.stringify(RP)
            };

            await apiEmpresas.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        getEmpleados(){

            let registrosPatronalesSeleccionados = [];
            let empresasSeleccionadas = [];
            this.empleados = [];

            if(this.registro_patronal_id == null){
                return;
            }
            if(this.empresa_value == null){
                return;
            }

            registrosPatronalesSeleccionados.push(this.registro_patronal_id);
            empresasSeleccionadas.push(this.empresa_value);

            let parametros = { 
                paginate: false,
                activo: true,
                usuario_id : this.datosLogin.id,
                cliente_id: this.cliente_value, 
                rol_name: this.datosLogin.rol.name,
                arrayRegistroPatronal : registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(registrosPatronalesSeleccionados), 
                arrayEmpresa : empresasSeleccionadas.length == 0 ? null:JSON.stringify(empresasSeleccionadas),
            };

            this.loadingEmpleados = true;
            apiEmpleados.getEmpleados(parametros).then((response)=>{
                this.itemsEmpleados = response.data;
                this.itemsEmpleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal + " - "+emp.nombre_departamento);
                this.loadingEmpleados = false;
            })
            .catch(error => {
                this.loadingEmpleados = false;
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        getCampos(){
            let param = { 
                activo: true, 
                paginate: false,
                cliente_id: this.cliente_value
            };
            this.itemsCampos = [];
            apiContratos.findCampos(param).then((response) => {
                this.itemsCampos = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los campos.");
            });
        },

        abrirModalLayoutEmpleados(accion){
            this.accionLayout = accion;
            this.dialogLayoutEmpleados = true;
            this.isLoading = true;
            if(accion === 'descargar' ){
                this.tituloLayoutEmpleados  = 'Descargar layout';
            }
            else if(accion === 'subir'){
                this.tituloLayoutEmpleados  = 'Subir layout';
            }

            if(this.rol == "root"){
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.archivo.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            this.isLoading = false;
        },
        modalLayout(accion) {
            this.accionLayout = accion;
            if(accion === 'importar' ){
                this.tituloLayoutEmpleados  = 'Subir layout';
            }
            this.archivo.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
            

            setTimeout(function() {
                document.getElementById("btnModalLayout").click();
            }, 300);
        },
        async layout() {
            let self = this;
            let url = "contratos-adicionales/exportar-layout";
            const FileDownload = require("js-file-download");
            let nombre;
            this.overlayDescarga = true;
            this.frase = "Descargando layout."
            if (this.rol == "admin" || this.rol == "root") {
                this.isSaving = true;
                nombre =
                    "layout_de_importacion" +".xlsx";
                var object1 = {
                    cliente_id: this.cliente_value,
                    registro_patronal_id: this.registro_patronal_id,
                    empresa_id: this.empresa_value,
                };
            }
            if (this.rol == "admin-empresa") {
                return;
            }
            try {
                await Vue.axios({
                    method: "post",
                    responseType: "blob",
                    url: url,
                    params: object1
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.cerrarModal();
                    this.overlayDescarga = false;
                })
                .catch(err => {
                    self.isSaving = false;
                    self.overlayDescarga = false;
                    Notify.ErrorToast("Error al descargar archivo.");
                });
            } catch (e) {
                this.overlayDescarga = false;
                this.isSaving = false;
                console.log(e);
            }
        },
        guardarLayout() {
            let self = this;
            this.overlayDescarga = true;
            this.frase = "Guardando datos."
            this.$refs.formArchivo.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                if(this.rol == "root" || this.rol == "admin"){
                    this.archivo.cliente_id = this.cliente_value;
                    this.archivo.registro_patronal_id = this.registro_patronal_id;
                    this.archivo.empresa_id = this.empresa_value;
                }
                else{
                    return;
                }
                let formData = new FormData();
                formData.append("archivo", this.archivo.archivo);
                formData.append("cliente_id", this.archivo.cliente_id);
                formData.append("registro_patronal_id", this.archivo.registro_patronal_id);
                formData.append("empresa_id", this.archivo.empresa_id);


                Vue.axios({
                    method: "post",
                    url: "contratos-adicionales/importar-layout",
                    data: formData,
                    config: { headers: { "Content-Type": "multipart/form-data" } }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        Notify.Success("Operación exitosa");
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                        
                    } else if (response.status == 202) {
                        Notify.ErrorToast("Se encontraron errores en los datos");
                        
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                        
                    } else {
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                    }
                    self.overlayDescarga = false;
                })
                .catch(err => {
                    self.isSaving = false;
                    self.overlayDescarga = false;
                    Notify.ErrorToast("Error al subir archivo.");
                });
            });
        },

        async loadModalData(){
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
            } 
        },

        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. Y se reflejan en la tabla principal.
         * Se ejecuta como emit del componente tabla.
         */
        setFilters() {
            let cliente = this.cliente_value;
            let rp      = this.registro_patronal_id;
            let empresa = this.empresa_value;
            let nombre  = this.nombre_empleado;

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
            } 

            let filterParams = {
                cliente_id          : cliente,
                empresa_id          : empresa,
                registro_patronal_id: rp,
                nombre_empleado     : nombre,
            };

            let defaultParams = {
                activo    : true,
                paginate  : true,
                order     : "asc"
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters                = defaultParams;
            this.cliente_value          = null;
            this.empresa_value          = null;
            this.registro_patronal_id   = null;
        },

        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("contratos-adicionales.datos.store")
            this.permisos.update = permisosStorage.includes("contratos-adicionales.datos.update")
            this.permisos.delete = permisosStorage.includes("contratos-adicionales.datos.delete")
        },
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.permisosVista();


        if(this.rol != 'root'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.columns     = [
                {
                    label: 'Empleado',
                    name: 'empleado_id',
                    filterable: false
                },
                {
                    label: 'Registro patronal',
                    name: 'registro_patronal',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'empresa_nombre',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ]
        }

    }
}
</script>

<style scoped>
    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0px 0px 0px 0px hsla( 0,0%,0%,.2 );

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border: 0px solid;
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0px;
        background: red !important;
        border-radius: 50px !important;
    }
    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 100%;
        min-height: 10.6cm;
        zoom:90%;
        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl( 0,0%,82.7% ) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

        /* Center the "page". */
        margin: 0 auto;
    }

    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }


    
</style>
